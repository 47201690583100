.Bio {
  display: flex;
  flex-direction: row;
  margin-bottom: 4.375rem;
}
  
.Bio img {
  float: left;
  border-radius: 50%;
  flex-shrink: 0;
  height: 8.5rem;
  margin-bottom: 0;
  margin-right: 1.875rem;
  width: 8.5rem;
}