.Social {
  display: flex;
  flex-direction: column;
}

.Social img {
  float: left;
  height: 2.0rem;
  margin-top: 0.5rem;
  margin-left: -0.3rem;
  margin-right: 0.5rem;
  width: 2.0rem;
}

.igtext {
  margin-top: 0.55rem;
}